import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ViewportRuler } from '@angular/cdk/overlay';
import { MutationObserverFactory } from '@angular/cdk/observers';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableScrollerWidgetComponent } from './table-scroller-widget.component';

@Directive({
  selector: '[akTableScroll]',
})
export class TableScrollWidgetDirective implements OnInit, OnDestroy, AfterViewInit {
  // a reference to the table scroll widget component
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('akTableScroll') tableScrollWidget: TableScrollerWidgetComponent;

  private ignoreEventsSubject = new BehaviorSubject<boolean>(false);
  private mutationObserver: MutationObserver;
  private cleanupSubject = new Subject();

  // react to the table being scrolled horizontally
  @HostListener('scroll', ['$event']) private onScroll(event: Event): void {
    // update the widget to show the correct scroll position
    this.tableScrollWidget.scrollPositionPercentage =
      ((<HTMLElement>event.target).scrollLeft / this.hostTableElement.nativeElement.scrollWidth) * 100;
  }



  constructor(
    private hostTableElement: ElementRef<HTMLTableElement>,
    private viewportRuler: ViewportRuler,
    private renderer2: Renderer2,
    private mutationObserverFactory: MutationObserverFactory,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.tableScrollWidget.scrollChanged.pipe(takeUntil(this.cleanupSubject)).subscribe((scrollFraction) => {
      const tableWidth = this.hostTableElement.nativeElement.scrollWidth;
      this.hostTableElement.nativeElement.scrollLeft = Math.round(tableWidth * (scrollFraction / 100));
    });
  }

  ngAfterViewInit() {
    // if the table is a material table, the content will not be rendered straight away.
    // we use mutationObserver to respond when the table DOM is updated

    this.mutationObserver = this.mutationObserverFactory.create(() => {
      // MutationObserver runs outside of ngZone and so we need to force this into the zone
      this.ngZone.run(() => {
        this.updateWidget();
      });
    });

    this.mutationObserver.observe(this.hostTableElement.nativeElement, { childList: true, subtree: true });

    this.updateWidget();

    // we use viewportRuler to respond when the window is resized
    this.viewportRuler
      .change(300)
      .pipe(takeUntil(this.cleanupSubject))
      .subscribe((_size) => {
        // ViewportRuler runs outside of ngZone and so we need to force this into the zone
        this.ngZone.run(() => {
          this.updateWidget();
        });
      });
  }

  ngOnDestroy() {
    this.cleanupSubject.next(null);
    this.mutationObserver?.disconnect();
  }

  private updateWidget() {
    /* Timeout needed to prevent 'ExpressionChangedAfterItHasBeenCheckedError' error when the client is not using onPush */
    setTimeout(() => {
      const visibleWidthOfTable = this.hostTableElement.nativeElement.getBoundingClientRect().width;

      const viewPercentageOfScroll = (visibleWidthOfTable / this.hostTableElement.nativeElement.scrollWidth) * 100;
      this.tableScrollWidget.columnPercentageWidths = this.getPercentageColumnWidths(this.hostTableElement.nativeElement);
      this.tableScrollWidget.visibleWidthPercentage = viewPercentageOfScroll;
    })
  }

  private getPercentageColumnWidths(tableElement: HTMLTableElement): number[] {
    return Array.from(tableElement?.rows[0]?.cells || []).map((cell) => {
      return (cell.offsetWidth / tableElement.scrollWidth) * 100;
    });
  }
}
