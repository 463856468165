import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatSnackBarRef as MatSnackBarRef } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import type { MessageBoxDataConfig } from '../model/message-box-data-config';

@Component({
  selector: 'ak-message-box-snackbar',
  templateUrl: '../template/message-box-overlay.component.html',
  styleUrls: ['./message-box-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessageBoxSnackbarComponent {
  constructor(
    public snackbarRef: MatSnackBarRef<MessageBoxSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: MessageBoxDataConfig,
    private sanitizer: DomSanitizer,
  ) {
  }

  get safeText(): SafeHtml {
    const parsedText = new DOMParser().parseFromString(this.data.text, 'text/html').documentElement.textContent;
    return this.sanitizer.bypassSecurityTrustHtml(parsedText);
  }
}
