<section>

  <div class="search-mobile">
    <mat-form-field>
      <input matInput type="text" aria-label="Search" placeholder="Search" [formControl]="searchQueryControl" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onLanguageChange($event.option.value)" [displayWith]="getLanguageDisplayText">
        <mat-option *ngFor="let language of filteredLanguages$ | async" [value]="language">
          {{language.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="suggested-languages-list">

    <div class="suggestion" *ngIf="selectedLanguage">
      <a [class.active]="true"
         (click)="onLanguageChange(selectedLanguage)">
      {{selectedLanguage.name}}</a>
    </div>

    <div class="suggestion" *ngIf="browserLanguage && (browserLanguage !== selectedLanguage)">
      <a (click)="onLanguageChange(browserLanguage)">
      {{browserLanguage.name}}</a>
    </div>

    <div class="suggestion" *ngIf="previousLanguage && (previousLanguage !== browserLanguage) && (previousLanguage !== selectedLanguage)">
      <a (click)="onLanguageChange(previousLanguage)">
        {{previousLanguage.name}}</a>
    </div>

  </div>


  <div class="languages">
    <ul *ngFor="let group of languageGroups">
      <li *ngFor="let language of group.languages">
        <a (click)="onLanguageChange(language)" *ngIf="selectedLanguage?.code !== language?.code"
        >{{language.name}}</a>
      </li>
    </ul>
  </div>

</section>
