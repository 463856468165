import { Component, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {CookieManagerChange, CookieManagerConfig} from './cookie-manager-config';
import { MatSlideToggle as MatSlideToggle, MatSlideToggleChange as MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'ak-cookie-manager',
  templateUrl: './cookie-manager.component.html',
  styleUrls: ['./cookie-manager.component.scss']
})
export class CookieManagerComponent {
  @Input() config: CookieManagerConfig;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<CookieManagerChange>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();

  @ViewChildren('cookieToggle') private _cookieToggles: QueryList<MatSlideToggle>;
  @ViewChild('cookieGroupToggle') private _groupToggle: MatSlideToggle;

  showCookieManagerSettings = false;

  onSave(): void {
    this.close.emit();
  }

  acceptOnlyEssential(): void {
    this.setOptionalCookies(false);
    this.close.emit();
  }

  acceptAll(): void {
    this.setOptionalCookies(true);
    this.close.emit();
  }

  showShowCookieSettings(): void {
    this.showCookieManagerSettings = true;
  }

  toggleGroup(event: MatSlideToggleChange): void {
    this._cookieToggles.map(cookieToggle => {
      cookieToggle.checked = event.checked;
      this.notifyCookieUpdate(cookieToggle.id, event.checked);
    });
  }

  toggleCookie(): void {
    this._cookieToggles.map(slideToggle => this.notifyCookieUpdate(slideToggle.id, slideToggle.checked));
    this.updateGroupToggle();
  }

  updateGroupToggle(): void {
    this._groupToggle.checked = this._cookieToggles.some(slideToggle => slideToggle.checked === true);
  }

  setOptionalCookies(cookieValue: boolean): void {
    this.config.optional.cookies.forEach(cookie => {
      cookie.checked = cookieValue;
      this.notifyCookieUpdate(cookie.name, cookieValue);
    });
  }

  notifyCookieUpdate(cookieId: string, value: boolean): void {
    this.change.emit(new CookieManagerChange(cookieId, value));
  }
}
