<ak-message-box-core
  class="message-box-core"
  [actionButtonText]="actionButtonText"
  [showCloseButton]="showCloseButton"
  (onActionButtonClicked)="onActionButtonClicked.emit(true)"
  (onMessageBoxClosed)="onMessageBoxClosed.emit(true)"
  [type]="type"
>
  <ng-content></ng-content>
</ak-message-box-core>
