<ak-message-box-core
  [actionButtonText]="data.actionButtonText"
  [showCloseButton]="data.showCloseButton"
  (onActionButtonClicked)="data.onActionButtonClicked($event)"
  (onMessageBoxClosed)="data.onMessageBoxClosed($event)"
  [type]="data.type"
>
  <span class="message-box-text" *ngIf="data.text" [innerHTML]="safeText"></span>
  <div class="message-box-template" *ngIf="data.template">
    <ng-container
      [ngTemplateOutlet]="data.template"
      [ngTemplateOutletContext]="data.templateContext"
    ></ng-container>
  </div>

</ak-message-box-core>
