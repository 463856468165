import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

interface ScrollPosition {
  scrollHeight: number;
  scrollTop: number;
  contentHeight: number;
}

/**
 * @deprecated this directive is deprecated and will be removed in the next major release
 *
 * Use the standard `ngx-infinite-scroll` package instead
 */
@Directive({
  selector: '[akInfiniteScroller]'
})
export class InfiniteScrollerDirective implements AfterViewInit, OnDestroy {
  @Input() scrollPercent = 90;
  @Input() initialPage = 1;
  @Input() totalPages = 1;
  @Input() isLoading = true;
  @Input() useWindow = true;
  @Output() nextPage = new EventEmitter<number>();

  private scrollSubscription: Subscription;

  constructor(private el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.registerScrollEvent();
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollSubscription.unsubscribe();
    }
  }

  private registerScrollEvent() {
    this.scrollSubscription = this.getScrollSource()
      .pipe(
        map(this.mapToScrollPosition.bind(this)),
        pairwise(),
        filter(positions => this.isUserScrollingDown(positions) && this.isScrollExpectedPercent(positions[1])),
        filter(() => this.initialPage < this.totalPages),
        filter(() => !this.isLoading)
      )
      .subscribe(() => {
        this.nextPage.emit(++this.initialPage);
      });
  }

  private mapToScrollPosition(e: any): ScrollPosition {
    let result: ScrollPosition;

    if (this.useWindow) {
      result = {
        scrollHeight: document.scrollingElement.scrollHeight,
        scrollTop: document.scrollingElement.scrollTop,
        contentHeight: document.documentElement.clientHeight
      };
    } else {
      result = {
        scrollHeight: e.target.scrollHeight,
        scrollTop: e.target.scrollTop,
        contentHeight: e.target.offsetHeight
      };
    }

    return result;
  }

  private getScrollSource(): Observable<Event> {
    if (this.useWindow) {
      return fromEvent(window, 'scroll');
    } else {
      return fromEvent(this.el.nativeElement, 'scroll');
    }
  }

  private isScrollExpectedPercent(position) {
    return (position.scrollTop + position.contentHeight) / position.scrollHeight > this.scrollPercent / 100;
  }

  private isUserScrollingDown(positions) {
    return !this.el.nativeElement.hidden && positions[0].scrollTop < positions[1].scrollTop;
  }
}
