import { Directive, HostBinding, Input} from '@angular/core';

@Directive({
  // eslint-disable-next-line  @angular-eslint/directive-selector
  selector: '[rippleHover], rippleHover'
})
export class RippleDirective  {

  @HostBinding(`class.ripple-hover-overlay`) get beforeHover() {
    return ! this.rippleHoverDisabled && this.rippleHoverPseudoElement === 'before';
  }

  @HostBinding('class.ripple-hover-overlay-after') get afterHover() {
    return ! this.rippleHoverDisabled && this.rippleHoverPseudoElement === 'after';
  }
  @Input() rippleHoverPseudoElement: 'before' | 'after' = 'before';
  @Input() rippleHoverDisabled = false;

  readonly BEFORE_PSEUDO_ELEMENT_CLASS = 'ripple-hover-overlay';
  readonly AFTER_PSEUDO_ELEMENT_CLASS = 'ripple-hover-overlay-after';
}
