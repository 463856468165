export interface CookieManagerConfig {
  subHeadline: string;
  actions: CookieManagerActions;
  essential?: CookieManagerGroup;
  optional?: CookieManagerGroup;
}

interface CookieManagerActions {
  settings: string;
  save: string;
  acceptEssential: string;
  acceptAll: string;
}

export interface CookieManagerGroup {
  name: string;
  checked?: boolean;
  cookies?: CookieManagerCookie[];
}

export interface CookieManagerCookie {
  name: string;
  checked: boolean;
  link?: CookieLink;
  properties: CookieManagerProperty[];
}

export interface CookieLink {
  name: string
  value : Link
}

export interface Link {
  href: string;
  text: string;
}

export interface CookieManagerProperty {
  name: string;
  value: string;
}

export enum COOKIE_PROPERTY {
  NAME = 'name',
  PROVIDER = 'provider',
  PURPOSE = 'purpose',
  VALUE = 'value',
  EXPIRATION = 'expiration'
}

export class CookieManagerChange {
  cookieName: string;
  checked: boolean;

  constructor(cookieName: string, checked: boolean) {
    this.cookieName = cookieName;
    this.checked = checked;
  }
}
