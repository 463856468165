<div
  matRipple [matRippleDisabled]="disabled" rippleHover [rippleHoverDisabled]="disabled"
  [ngClass]="{
    'input-drop': true,
    disabled: disabled
  }"
  (click)="zoneClicked$.next($event)"
  (drop)="fileDropped$.next($event)"
  (dragover)="dragOver$.next($event)"
  (dragleave)="dragLeave$.next($event)"
  (keyup.enter)="zoneClicked$.next($event)"
  tabindex="0"
  role="button"
>
  <div class="input-message">
    <mat-icon class="upload-icon" svgIcon="ak-upload"></mat-icon>
    {{ title }}
  </div>
</div>
<input
  #fileInput
  type="file"
  (change)="this.fileSelected$.next($event)"
  [accept]="acceptedTypes?.join(',')"
  multiple
  class="file-input-field"
/>

