<div *ngIf="showMessageBox" class="message-box {{ type }}">
  <div class="message-box-icon">
    <mat-icon class="icon" [svgIcon]="icon"></mat-icon>
  </div>
  <div class="message-box-content">
    <ng-content></ng-content>
    <div class="message-box-content-action">
      <button
        *ngIf="actionButtonText"
        (click)="doAction()"
        aria-label="action"
        class="message-box-action-button"
        mat-flat-button
      >
        {{ actionButtonText }}
      </button>
    </div>
  </div>
  <div *ngIf="showCloseButton" class="message-box-close-button">
    <button
      (click)="close()"
      aria-label="close"
      class="close-button"
      mat-icon-button
    >
      <mat-icon svgIcon="ak-close"></mat-icon>
    </button>
  </div>
</div>
