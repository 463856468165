import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDropzoneComponent } from './file-dropzone.component';
import { MatIconModule } from '@angular/material/icon';
import {MatRippleModule} from "@angular/material/core";
import {RippleModule} from "../ripple/ripple.module";

@NgModule({
  imports: [CommonModule, MatIconModule, MatRippleModule, RippleModule],
  declarations: [FileDropzoneComponent],
  exports: [FileDropzoneComponent]
})
export class FileDropzoneModule {}
