<div class="button-container">
  <button
    *ngIf="buttonText"
    mat-flat-button
    [color]="inProgress ? null : color"
    [disabled]="inProgress || disabled"
    [ngClass]="buttonClass"
    [attr.aria-label]="buttonAriaLabel"
    [type]="type"
    (click)="clicked.emit()"
    class="submit-button"
  >
    <mat-icon
      *ngIf="svgIconName"
      [svgIcon]="svgIconName"
      [hidden]="inProgress"
      [ngClass]="{ 'in-progress': inProgress }"
    ></mat-icon>
    <span [ngClass]="{ 'button-text': true, 'in-progress': inProgress }">{{ buttonText }}</span>
  </button>


  <button
    *ngIf="! buttonText"
    mat-icon-button
    [color]="inProgress ? null : color"
    [disabled]="inProgress || disabled"
    [ngClass]="buttonClass"
    [type]="type"
    [attr.aria-label]="buttonAriaLabel"
    (click)="clicked.emit()"
    class="submit-button"
  >
    <mat-icon
      *ngIf="svgIconName"
      [svgIcon]="svgIconName"
      [hidden]="inProgress"
      [ngClass]="{ 'in-progress': inProgress }"
    ></mat-icon>
  </button>

  <div class="spinner-container"><mat-spinner *ngIf="inProgress" [attr.aria-label]="inProgressAriaLabel" [diameter]="20"></mat-spinner></div>
</div>
