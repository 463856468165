import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MenuItem } from './menu-item';
import { NestedTreeControl } from '@angular/cdk/tree';
import { NavigationEnd, Router } from '@angular/router';
import { MenuEvent } from './menu.event';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ak-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnDestroy {
  treeControl: NestedTreeControl<MenuItem>;
  activeMenu: MenuItem;
  @Output() menuClicked: EventEmitter<MenuEvent> = new EventEmitter();
  private levels: Map<string, number> = new Map<string, number>();
  private _menuItems: MenuItem[];
  private routerSubscription: Subscription;

  constructor(private router: Router) {
    this.treeControl = new NestedTreeControl<MenuItem>((node) => node.children);
    this.treeControl.getLevel = (node) => this.levels.get(node.path);
    this.listenToRouterChange();
  }

  private listenToRouterChange() {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.resetActiveMenu();
        this.expandActivePath(this._menuItems);
      }
    });
  }

  get menuItems() {
    return this._menuItems;
  }

  @Input()
  set menuItems(_menuItems: MenuItem[]) {
    this._menuItems = _menuItems;
    this.addLevelInformation();
    this.resetActiveMenu();
    this.expandActivePath(this._menuItems);
  }

  hasChild = (_: number, node: MenuItem) => !!node.children && node.children.length > 0;

  menuClickedEvent($event: MouseEvent, menuItem: MenuItem) {
    this.triggerMenuClickEvent($event, menuItem);

    if (!menuItem.path.startsWith('http')) {
      if (menuItem !== this.activeMenu) {
        this.router.navigateByUrl( menuItem.path); // after routing it will expand and set the active menu
        $event.stopPropagation();
      }
      $event.preventDefault(); // avoid executing href
    }
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private addLevelInformation() {
    this.levels.clear();
    this.addLevelInformationRecursive(this._menuItems, 0);
  }

  private addLevelInformationRecursive(menuItems: MenuItem[], level: number) {
    menuItems.forEach((menuItem) => {
      this.levels.set(menuItem.path, level);
      if (!!menuItem.children) {
        this.addLevelInformationRecursive(menuItem.children, level + 1);
      }
    });
  }

  private triggerMenuClickEvent($event: MouseEvent, menuItem: MenuItem) {
    const menuEvent: MenuEvent = { $event, menuItem: { ...menuItem } };
    this.menuClicked.emit(menuEvent);
  }

  private expandActivePath(menuItems: MenuItem[]): void {

    if (!menuItems) {
      return;
    }

    const currentLocation = this.router.url.split('?')[0];

    // look at the longest paths first so that more specific routes match first (and everything doesn't just match /)
    const sortedMenuItems = [...menuItems].sort(MenuComponent.sortByStringLength);

    for (const menuItem of sortedMenuItems) {

      const path = menuItem.path.split('?')[0];

      if (currentLocation && currentLocation.startsWith(path)) {
        this.treeControl.expand(menuItem);
        this.activeMenu = menuItem;

        this.expandActivePath(menuItem.children);
        return;
      }
    }
  }

  private resetActiveMenu() {
    this.activeMenu = null;
  }

  private static sortByStringLength(a: MenuItem, b: MenuItem) {
    if (a.path.length < b.path.length) {
      return 1;
    } else {
      return -1;
    }
  }
}
