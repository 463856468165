import { Component, OnInit, Input, forwardRef, OnDestroy, Output, EventEmitter, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, filter, tap } from 'rxjs/operators';

interface ViewData {
  selectedFiles: File[];
  maxFilesReached: boolean;
  selectedCount: number;
}

@Component({
  selector: 'ak-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true
    }
  ]
})
export class InputFileComponent implements OnInit, OnDestroy, ControlValueAccessor {

  // an optional template reference to render a non-standard selected file preview section
  @Input() selectedFilesTemplate: TemplateRef<{ files: File[], removeFileFunction: (index: number) => {} }>;

  // the text to be shown within the dropzone
  @Input() title: string;

  // maximum number of files which can be added. File-drops which add more than this amount will add files until the maximum is reached
  // the rest will be ignored
  @Input() maxFiles: number;

  // an array of accepted MIME-types. Wildcard types such as image/* are accepted
  // a single, comma-separated string is supported for legacy reasons
  @Input() set acceptString(accept: string | string[]) {
    if (!accept) {
      this.acceptStringList = []
    } else {
      this.acceptStringList = Array.isArray(accept) ? accept : accept?.split(',');
    }
  }

  // emits a list of files which could not be added because of an invalid MIME-type
  @Output() filesRejected = new EventEmitter<string[]>();

  acceptStringList: string[] = [];

  objectKeys = Object.keys;

  fileRemoved$ = new Subject<string>();
  filesAdded$ = new Subject<File[]>();

  internalValue$ = new BehaviorSubject<File[]>([]);
  cleanupSubject$ = new Subject();

  isDisabled = false;

  viewData$: Observable<ViewData> = this.internalValue$.pipe(
    takeUntil(this.cleanupSubject$),
    map((currentValue) => ({
      selectedFiles: currentValue,
      maxFilesReached: Object.keys(currentValue).length === this.maxFiles,
      selectedCount: Object.keys(currentValue).length,
    }))
  );

  writeValue(files: File[]): void {
    if (files) {
      this.internalValue$.next(files);
    } else {
      this.internalValue$.next([]);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onChange = (_files: any) => {};
  onTouch = () => {};

  constructor() {}

  ngOnInit() {
    this.filesAdded$
      .pipe(
        takeUntil(this.cleanupSubject$),
        tap(this.onTouch.bind(this)),
        filter((files) => !!files && !!files[0])
      )
      .subscribe(this.addFiles.bind(this));

    this.fileRemoved$.pipe(takeUntil(this.cleanupSubject$)).subscribe(this.removeFile.bind(this));

    this.internalValue$.pipe(takeUntil(this.cleanupSubject$)).subscribe();
  }

  ngOnDestroy(): void {
    this.cleanupSubject$.next(null);
    this.cleanupSubject$.complete();
  }

  private addFiles(files: File[] = []): void {
    if (!files || files.length === 0 || !this.canAddMoreFiles()) {
      return;
    }

    const currentFiles = this.getCurrentInternalValue();
    const newFiles = this.filterOutSelectedFiles(files, currentFiles);
    const filesToAdd = this.filterOutExceedingFiles(newFiles);

    this.updateInternalValue([...currentFiles, ...filesToAdd]);
  }

  private getCurrentInternalValue(): File[] {
    return this.internalValue$.getValue();
  }

  private filterOutExceedingFiles(files: File[]): File[] {
    if (!this.maxFiles) {
      return files;
    }

    const currentFilesCount = this.getCurrentInternalValue().length;
    const availableSlotsSize = Math.min(this.maxFiles, this.maxFiles - currentFilesCount);

    return files.slice(0, availableSlotsSize);
  }

  private filterOutSelectedFiles(newFiles: File[], currentFiles: File[]): File[] {
    return newFiles.filter(file => !this.isFileAlreadySelected(file, currentFiles));
  }

  private isFileAlreadySelected(file: File, existingFiles: File[]): boolean {
    return existingFiles.some(existingFile =>
      existingFile.name === file.name && existingFile.size === file.size
    );
  }

  private updateInternalValue(newValue: File[]) {
    this.internalValue$.next(newValue);
    this.notifyValueChange(newValue);
  }

  removeFile(index: number) {
    const value = this.internalValue$.getValue();
    value.splice(index, 1);

    this.notifyValueChange(value);
    this.internalValue$.next(value);
  }

  notifyValueChange(value: File[]) {
    this.onTouch();
    this.onChange(value);
  }

  private canAddMoreFiles(): boolean {
    const isUnlimited = this.maxFiles === undefined;
    const isBelowMax = this.maxFiles > this.internalValue$.getValue().length;
    return isUnlimited || isBelowMax;
  }
}
