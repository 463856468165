import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MenuLinkTargetPipe } from './menu-link-target.pipe';
import {MatRippleModule} from "@angular/material/core";
import {RippleModule} from "../ripple/ripple.module";

@NgModule({
  declarations: [MenuComponent, MenuLinkTargetPipe],
  imports: [CommonModule, MatIconModule, CdkTreeModule, MatButtonModule, MatIconModule, RouterModule, MatRippleModule, RippleModule],
  exports: [MenuComponent]
})
export class MenuModule {}
