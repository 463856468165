import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import type { NotificationType } from './model/notification-type';

@Component({
  selector: 'ak-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss'],
})
export class MessageBoxComponent {
  @Input() type: NotificationType;
  @Input() actionButtonText?: string;
  @Input() showCloseButton = false;

  @Output() onMessageBoxClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() onActionButtonClicked: EventEmitter<boolean> = new EventEmitter();
}
