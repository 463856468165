<cdk-tree [dataSource]="menuItems" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <cdk-nested-tree-node *cdkTreeNodeDef="let node">
    <!-- use a disabled button to provide padding for tree leaf -->
    <div [class.active]="activeMenu === node" matRipple class="ripple-hover-overlay">
      <a [href]="node.path" cdkTreeNodePadding [cdkTreeNodePaddingIndent]="16" (click)="menuClickedEvent($event, node)"
         [target]="node | linkTarget">
        <span class="leaf">
          {{ node.label }}
        </span>
      </a>
    </div>
  </cdk-nested-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild">
    <div cdkTreeNodePadding [cdkTreeNodePaddingIndent]="16" cdkTreeNodeToggle [class.active]="activeMenu === node" matRipple  class="ripple-hover-overlay-after">
      <button mat-icon-button [attr.aria-label]="'toggle ' + node.name">
        <mat-icon>{{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}</mat-icon>
      </button>

      <a [href]="node.path" (click)="menuClickedEvent($event, node)" *ngIf="!node.skipNavigation"
         [target]="node | linkTarget">
        {{ node.label }}
      </a>
      <span *ngIf="node.skipNavigation">
        {{ node.label }}
      </span>
    </div>
    <div *ngIf="treeControl.isExpanded(node)">
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>
</cdk-tree>
