import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CookieManagerComponent} from './cookie-manager.component';
import {MatSlideToggleModule as MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule as MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
  declarations: [CookieManagerComponent],
  imports: [CommonModule, MatButtonModule, MatExpansionModule, MatSlideToggleModule],
  exports: [CookieManagerComponent]
})
export class CookieManagerModule {}
