<button
  mat-icon-button
  class="header-button"
  [attr.aria-label]="profileButtonAriaLabel + '. ' + (showNotificationBadge ? notificationsAvailableAriaLabel : '')"
  [matMenuTriggerFor]="menuTriggerFor"
>
  <img
    [attr.alt]="profileImageAltText"
    *ngIf="src && !showFallback"
    class="user-picture"
    [src]="src"
    (error)="imageLoadFailed()"
  />
  <mat-icon *ngIf="!src || showFallback" [svgIcon]="svgIcon">{{ svgIcon ? '' : 'person' }}</mat-icon>

  <ng-container *ngIf="showNotificationBadge">
    <div [ngClass]="{ 'notification-badge': true, 'showing-picture': src && !showFallback }"></div>
    <div aria-live="polite">
      <span class="cdk-visually-hidden">{{ notificationsAvailableAriaLabel }}</span>
    </div>
  </ng-container>
</button>
