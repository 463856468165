import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule as MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule as MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule as MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule as MatButtonModule} from '@angular/material/button';
import {LanguagePickerComponent} from './language-picker.component';



@NgModule({
  declarations: [LanguagePickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule
  ],
  exports: [LanguagePickerComponent]
})
export class LanguagePickerModule { }
