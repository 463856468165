import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'ak-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss'],
  host: {
    // this helper class from the Akelius material-theme makes the button behave correctly when used in a dialog
    class: 'dialog-action-item',
  },
})
export class SpinnerButtonComponent implements OnInit {
  @Input() inProgress = false; // set to true when the button has been clicked but the action is not yet complete
  @Input() disabled = false; // button always disabled
  @Input() buttonText: string;
  @Input() svgIconName: string;
  @Input() color: ThemePalette; // primary, accent, warn
  @Input() buttonClass: string;
  @Input() type = 'button';
  @Input() inProgressAriaLabel = 'in progress';
  @Input() buttonAriaLabel = '';
  @Output() clicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
