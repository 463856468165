import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbDirective } from './directives/breadcrumb.directive';
import { MatIconModule } from '@angular/material/icon';
import { debounceTime, startWith, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ak-breadcrumbs',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  template: `
    <h1 *ngIf="title">
      {{ title }}
    </h1>

    <div class="content" [ngClass]="{ expanded: isExpanded, hidden: isHidden }">
      <span #root>
        <ng-container *ngTemplateOutlet="firstItem.templateRef" />
        <mat-icon svgIcon="ak-chevron-right" />
      </span>

      <span #threeDots *ngIf="hasHiddenItems && !isExpanded" class="three-dots" (click)="expandAll()"
        >...
        <mat-icon svgIcon="ak-chevron-right" />
      </span>

      <span #viewItem *ngFor="let item of items; let last = last">
        <ng-container *ngTemplateOutlet="item.templateRef" />
        <mat-icon *ngIf="!last || showTrailingChevron" svgIcon="ak-chevron-right" />
      </span>
    </div>
  `,
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements AfterViewInit, OnDestroy {
  @ContentChildren(BreadcrumbDirective) contentChildren: QueryList<BreadcrumbDirective>;

  @Input({ required: false }) title: string | null = null;
  @Input({ required: false }) showTrailingChevron = true;

  @ViewChild('root') root: ElementRef;
  @ViewChild('threeDots') threeDots: ElementRef;
  @ViewChildren('viewItem', { read: ElementRef }) viewItems: QueryList<ElementRef>;
  @HostBinding('class.hidden') isHidden = false;

  @HostListener('window:resize')
  onResize(): void {
    this.calculateHiddenItems();
  }

  rootWidth: number;
  itemWidths: number[] = [];
  hiddenItemCount = 0;
  isExpanded = false;

  private subscription = new Subscription();

  get threeDotsWidth(): number {
    return this.threeDots?.nativeElement?.offsetWidth ?? 0;
  }

  get hasHiddenItems(): boolean {
    return this.hiddenItemCount > 0;
  }

  get firstItem(): BreadcrumbDirective {
    return this.contentChildren.toArray()[0];
  }

  get items(): BreadcrumbDirective[] {
    return this.contentChildren.toArray().slice(this.hiddenItemCount + 1);
  }

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    /* Timeout needed to prevent 'ExpressionChangedAfterItHasBeenCheckedError' error when the client is not using onPush */
    setTimeout(() => {
      this.listenToItemsChange();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  expandAll(): void {
    this.isExpanded = true;
    this.calculateHiddenItems();
  }

  private expandSilently(): void {
    this.isHidden = true;
    this.hiddenItemCount = 0;
  }

  private listenToItemsChange(): void {
    let threeDotsWidth: number;
    this.subscription = this.contentChildren.changes
      .pipe(
        startWith([]),
        tap(() => {
          this.expandSilently();

          threeDotsWidth = this.threeDotsWidth;
        }),
        debounceTime(10),
      )
      .subscribe(() => {
        this.initItemWidths();
        this.calculateHiddenItems(threeDotsWidth);
        this.isHidden = false;
      });
  }

  private initItemWidths(): void {
    this.itemWidths = this.viewItems.map((item) => item.nativeElement.offsetWidth);
  }

  private calculateHiddenItems(threeDotsWidth = this.threeDotsWidth): void {
    const containerWidth = this.elementRef.nativeElement.offsetWidth;
    this.rootWidth = this.root?.nativeElement?.offsetWidth ?? 0;

    if (!this.isExpanded) {
      let count = this.hiddenItemCount;

      while (this.getTotalWidth(count, threeDotsWidth) > containerWidth && count < this.itemWidths.length) {
        count++;
      }

      while (this.getTotalWidth(count - 1, threeDotsWidth) <= containerWidth && count > 0) {
        count--;
      }

      this.hiddenItemCount = count;
    } else {
      this.hiddenItemCount = 0;
    }
  }

  private getTotalWidth(hiddenItemsCount: number, threeDotsWidth = this.threeDotsWidth): number {
    const itemsWidth = this.itemWidths
      .slice(hiddenItemsCount, this.itemWidths.length)
      .reduce((acc, width) => acc + width, 0);

    return this.rootWidth + threeDotsWidth + itemsWidth;
  }
}
