  import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';

@Component({
  selector: 'ak-table-scroller-widget',
  templateUrl: './table-scroller-widget.component.html',
  styleUrls: ['./table-scroller-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableScrollerWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('widgetContainer') widget: ElementRef<HTMLElement>;
  @ViewChild('dragMarker') dragMarker: ElementRef<HTMLElement>;

  // a list of column widths represented as percentages
  @Input() set columnPercentageWidths(widths: number[]) {
    this.columnPercentageWidthsSubject.next(widths);
  }

  // the percentage of total table width which is visible on the screen
  @Input() set visibleWidthPercentage(percentage: number) {
    this.visibleWidthPercentageSubject.next(percentage);
  }

  // set the scroll position - used when the used scrolls the table manually
  @Input() set scrollPositionPercentage(position: number) {
    if (this.inProgressDragSubject.getValue() === null) {
      this.scrollPositionPercentageSubject.next(position);
    }
  }

  // emits the percentage to which the widget has been scrolled
  @Output() scrollChanged = new EventEmitter<number>();

  // a list of the column widths in the table as percentages
  columnPercentageWidthsSubject = new BehaviorSubject<number[]>([]);

  // the percentage of the total width of the table visible in the browser
  visibleWidthPercentageSubject = new BehaviorSubject<number>(0);

  // percentage the table is scrolled
  scrollPositionPercentageSubject = new BehaviorSubject<number>(0);

  // the current offset of the active drag (can be positive/negative)
  inProgressDragSubject = new BehaviorSubject<number>(null);

  private cleanupSubject = new Subject();

  freeDragPosition$ = combineLatest([this.scrollPositionPercentageSubject, this.inProgressDragSubject]).pipe(
    takeUntil(this.cleanupSubject),
    filter((_) => !!this.widget),
    map(([scrollPosition, inProgressDrag]) => {
      return inProgressDrag === null ? this.percentageToPixels(scrollPosition) : null;
    }),
    map((xPosition: number) => {
      return xPosition !== null
        ? {
            y: 0,
            x: xPosition,
          }
        : null;
    })
  );

  constructor() {}

  ngOnInit(): void {
    combineLatest([
      this.scrollPositionPercentageSubject,
      this.inProgressDragSubject,
      this.visibleWidthPercentageSubject,
    ])
      .pipe(
        takeUntil(this.cleanupSubject),
        filter((_) => !!this.widget)
      )
      .subscribe(([scrollPositionPercentage, dragPosition]) => {
        const percentagePosition =
          dragPosition !== null ? this.pixelsToPercentage(dragPosition) : scrollPositionPercentage;

        this.scrollChanged.emit(percentagePosition);
      });
  }

  ngOnDestroy() {
    this.cleanupSubject.next(null);
  }

  dragEnd(event: CdkDragEnd) {
    // when the drag ends we update the scroll position

    const currentPositionPixels = this.percentageToPixels(this.scrollPositionPercentageSubject.getValue());

    const maxPercentage = 100 - this.visibleWidthPercentageSubject.getValue();
    const newPercentage = this.pixelsToPercentage(currentPositionPixels + event.distance.x);
    const constrainedPercentage = Math.min(Math.max(newPercentage, 0), maxPercentage);

    this.scrollPositionPercentageSubject.next(constrainedPercentage);
    this.inProgressDragSubject.next(null);
  }

  updateScroll(event: CdkDragMove) {
    const currentScrollPosition = this.scrollPositionPercentageSubject.getValue();

    const maxDragPercentage = 100 - this.visibleWidthPercentageSubject.getValue();
    const maxDragPixels = this.percentageToPixels(maxDragPercentage);

    const currentPositionPixels = this.percentageToPixels(currentScrollPosition);
    const positionAfterDrag = currentPositionPixels + event.distance.x;

    const constrainedDrag = Math.min(Math.max(positionAfterDrag, 0), maxDragPixels);

    this.inProgressDragSubject.next(constrainedDrag);
  }

  private percentageToPixels(percentage: number): number {
    return this.widget.nativeElement.offsetWidth * (percentage / 100);
  }

  private pixelsToPercentage(pixels: number): number {
    return (pixels / this.widget.nativeElement.offsetWidth) * 100;
  }
}
