<section>
  <p *ngIf="config.subHeadline" [innerHTML]="config.subHeadline"></p>

  <div class="actions">
    <a *ngIf="!showCookieManagerSettings" (click)="showShowCookieSettings()">{{ config.actions.settings }}</a>

    <button mat-flat-button *ngIf="showCookieManagerSettings" (click)="onSave()">
      {{ config.actions.save }}
    </button>

    <button mat-flat-button *ngIf="!showCookieManagerSettings" (click)="acceptOnlyEssential()">
      {{ config.actions.acceptEssential }}
    </button>

    <button mat-flat-button color="primary" (click)="acceptAll()">{{ config.actions.acceptAll }}</button>
  </div>

  <mat-accordion [togglePosition]="'before'" [multi]="true" *ngIf="this.showCookieManagerSettings">
    <!--Essential Cookies-->
    <mat-expansion-panel *ngIf="config.essential as cookieGroup">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2>{{ cookieGroup.name }}</h2>
        </mat-panel-title>
        <mat-panel-description (click)="$event.stopPropagation()">
          <mat-slide-toggle [checked]="true" [disabled]="true"></mat-slide-toggle>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="cookie" *ngFor="let cookie of cookieGroup.cookies">
        <ng-container
          [ngTemplateOutlet]="cookieProperties"
          [ngTemplateOutletContext]="{ $implicit: cookie }"
        ></ng-container>
        <mat-slide-toggle [checked]="true" [disabled]="true"></mat-slide-toggle>
      </div>
    </mat-expansion-panel>

    <!--Optional Cookies-->
    <mat-expansion-panel *ngIf="config.optional as cookieGroup">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2>{{ cookieGroup.name }}</h2>
        </mat-panel-title>
        <mat-panel-description (click)="$event.stopPropagation()">
          <mat-slide-toggle
            #cookieGroupToggle
            [checked]="cookieGroup.checked"
            (change)="toggleGroup($event)"
          ></mat-slide-toggle>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="cookie" *ngFor="let cookie of cookieGroup.cookies">
        <ng-container
          [ngTemplateOutlet]="cookieProperties"
          [ngTemplateOutletContext]="{ $implicit: cookie }"
        ></ng-container>

        <mat-slide-toggle
          #cookieToggle
          [id]="cookie.name"
          [checked]="cookie.checked"
          (change)="toggleCookie()"
        ></mat-slide-toggle>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>



<ng-template #cookieProperties let-cookie>
  <table>
    <tr *ngFor="let property of cookie.properties">
      <td>{{ property.name }}</td>
      <td>{{ property.value }}</td>
    </tr>
    <tr *ngIf="cookie.link as link">
      <td>{{ link.name }}</td>
      <td>
        <a href="{{link.value.href}}" target="_blank">{{link.value.text}}</a>
      </td>
    </tr>
  </table>
</ng-template>
