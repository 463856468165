import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { filter, map, tap, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { merge, Observable, Subject } from 'rxjs';
import {MatRipple, RippleRef} from '@angular/material/core';

interface ValidationResult {
  accepted: File[];
  rejected: File[];
}

@Component({
  selector: 'ak-file-dropzone',
  templateUrl: './file-dropzone.component.html',
  styleUrls: ['./file-dropzone.component.scss'],
})
export class FileDropzoneComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', { read: ElementRef }) fileInput: ElementRef<HTMLInputElement>;

  @ViewChild(MatRipple) ripple: MatRipple;

  @Input() title: string;
  @Input() disabled = false;
  @Input() acceptedTypes: string[] = [];
  @Input() extensionCaseSensitive = false;
  @Input() clearSelectionAfterEmitted = false;

  @Output() filesSelected = new EventEmitter<File[]>();
  @Output() filesRejected = new EventEmitter<string[]>();

  private cleanupSubject = new Subject<any>();

  zoneClicked$ = new Subject<MouseEvent>(); // zone clicked
  fileDropped$ = new Subject<DragEvent>(); // file dropped onto the dropzone div
  fileSelected$ = new Subject<Event>(); // file selected in the hidden input file

  dragOver$ = new Subject<DragEvent>();
  dragLeave$ = new Subject<DragEvent>();

  filesAdded$: Observable<File[]> = merge(
    this.fileDropped$.pipe(
      tap(FileDropzoneComponent.containEvent),
      tap((_) => this.resetMatRippleRef()),
      filter((_) => !this.disabled),
      map(FileDropzoneComponent.extractDroppedFiles)
    ),
    this.fileSelected$.pipe(tap(FileDropzoneComponent.containEvent), map(FileDropzoneComponent.extractSelectedFiles))
  ).pipe(
    takeUntil(this.cleanupSubject),
    map(this.filterInvalidFiles.bind(this)),
    filter((files: File[]) => !!files.length)
  );


  matRippleRef: RippleRef;

  constructor() {}

  ngOnInit() {
    // trigger the file input dialog when the dropzone is clicked
    this.zoneClicked$
      .pipe(
        takeUntil(this.cleanupSubject),
        filter((_) => !this.disabled)
      )
      .subscribe(() => {
        this.fileInput.nativeElement.click();
      });

    // update the hover styles to show when a use has dragged files over the dropzone
    merge(
      this.dragLeave$.pipe(map((_) => false)),
      this.dragOver$.pipe(
        tap(FileDropzoneComponent.containEvent.bind(this)),
        filter((_) => !this.disabled),
        filter(FileDropzoneComponent.isFileDrag.bind(this)),
        map((_) => true)
      )
    )
      .pipe(takeUntil(this.cleanupSubject), distinctUntilChanged())
      .subscribe((isHovering: boolean) => {
        if (isHovering && !this.matRippleRef) {
          this.matRippleRef = this.ripple.launch({ centered: true, persistent: true });
        } else {
          this.resetMatRippleRef();
        }
      });

    // emit to fileSelected output, whichever was the files were added
    this.filesAdded$.subscribe((value) => this.emitSelectedFiles(value));
  }

  private resetMatRippleRef(): void {
    if (this.matRippleRef) {
      this.matRippleRef.fadeOut();
      this.matRippleRef = null;
    }
  }

  ngOnDestroy(): void {
    this.cleanupSubject.next(null);
    this.cleanupSubject.complete();
  }

  private emitSelectedFiles(selectedFiles: File[]) {
    this.filesSelected.emit(selectedFiles);

    if (this.clearSelectionAfterEmitted) {
      this.fileInput.nativeElement.value = '';
    }
  }

  private isAllowedFileType(file: File) {
    return !this.acceptedTypes || this.acceptedTypes.length === 0 || this.fileAccepted(file);
  }

  private fileAccepted(file: File) {
    return this.acceptedTypes.find((acceptedType) => {
      const isExtensionType = acceptedType.startsWith('.');
      return isExtensionType
        ? FileDropzoneComponent.extensionTypeMatches(file, acceptedType, this.extensionCaseSensitive)
        : FileDropzoneComponent.mimeTypeMatches(file, acceptedType);
    });
  }

  private static mimeTypeMatches(file: File, acceptedType: string) {
    // check if the type is a wildcard
    const match = acceptedType.match(/(.+)\*$/);

    if (match) {
      return file.type.startsWith(match[1]);
    } else {
      return acceptedType === file.type;
    }
  }

  private static extensionTypeMatches(file: File, acceptedType: string, caseSensitive: boolean = false) {
    if (caseSensitive) {
      return file.name.endsWith(acceptedType);
    }

    return file.name.toLowerCase().endsWith(acceptedType.toLowerCase());
  }

  private static containEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private static extractDroppedFiles(event: DragEvent): File[] {
    const files = event.dataTransfer.items;

    const fileObjects = [];
    for (let i = 0; i < files.length; i++) {
      const fileEntry = files[i];
      fileObjects.push(fileEntry.getAsFile());
    }

    return fileObjects;
  }

  private filterValidFiles(files: File[]): ValidationResult {
    return files.reduce(
      (object, file) => {
        const accepted = object.accepted;
        const rejected = object.rejected;
        this.isAllowedFileType(file) ? accepted.push(file) : rejected.push(file);
        return { accepted, rejected };
      },
      { accepted: [], rejected: [] }
    );
  }

  private filterInvalidFiles(files: File[]): File[] {
    const validationResult = this.filterValidFiles(files);

    if (validationResult.rejected.length) {
      this.filesRejected.emit(validationResult.rejected.map((file) => file.name));
    }

    return validationResult.accepted;
  }

  private static extractSelectedFiles(event: Event): File[] {
    const changeEvent = event.target as HTMLInputElement;
    return Array.from(changeEvent.files);
  }

  private static isFileDrag(event: DragEvent): boolean {
    for (let i = 0; i < event.dataTransfer.types.length; i++) {
      if (event.dataTransfer.types[i] === 'Files') {
        return true;
      }
    }
    return false;
  }
}
