import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationButtonColor } from './nav-button';

@Component({
  selector: 'ak-nav-button',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="icon-or-img" [ngClass]="color">
      <ng-content select="img"></ng-content>
      <ng-content select="mat-icon"></ng-content>
    </div>
    <ng-content></ng-content>
  `,
  styleUrls: ['./nav-button.component.scss'],
})
export class NavButtonComponent {
  @Input() color: NavigationButtonColor | null | undefined;
}
