import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TableScrollerWidgetComponent } from './table-scroller-widget.component';
import { TableScrollWidgetDirective } from './table-scroll-widget.directive';
import { RippleModule } from '../ripple/ripple.module';

@NgModule({
  declarations: [TableScrollerWidgetComponent, TableScrollWidgetDirective],
  imports: [CommonModule, DragDropModule, RippleModule],
  exports: [TableScrollerWidgetComponent, TableScrollWidgetDirective],
})
export class TableScrollerWidgetModule {}
