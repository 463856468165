import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import {LanguageGroup} from './language-group';
import {Language} from './language';
import {isPlatformBrowser} from '@angular/common';
import {Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {LANGUAGE_GROUPS} from './language-list';
import {LanguagePickerService} from './service/language-picker.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'ak-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerComponent implements OnInit {
  @Output() languageChange = new EventEmitter<Language>();
  @Input() previousLanguageCode: string;
  @Input() currentLang: string;
  @Input() browserLang: string;

  languageGroups: Array<LanguageGroup>;
  languages: Array<Language>;

  selectedLanguage: Language;
  browserLanguage: Language;
  previousLanguage: Language;

  searchQueryControl = new UntypedFormControl();
  filteredLanguages$: Observable<Array<Language>>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public languagePickerService: LanguagePickerService
  ) {
    this.languageGroups = LANGUAGE_GROUPS;
    this.languages = this.languagePickerService.getAllowedLanguages();
  }

  ngOnInit() {
    this.selectedLanguage = this.languages.find((language) => language.code === this.currentLang);
    this.previousLanguage = this.languages.find(
      (language) => this.previousLanguageCode && language.code === this.previousLanguageCode
    );
    if (isPlatformBrowser(this.platformId)) {
      this.browserLanguage = this.languages.find((language) => language.code === this.browserLang);
    }

    this.filteredLanguages$ = this.searchQueryControl.valueChanges.pipe(
      debounceTime(200),
      filter((value) => typeof value === 'string'),
      distinctUntilChanged(),
      switchMap((query) => this.filter(query))
    );
  }

  onLanguageChange(language: Language): void {
    this.languageChange.emit(language);
  }

  private filter(query: string): Observable<Array<Language>> {
    return of(this.languages.filter((language) => language.name.toLowerCase().indexOf(query.toLowerCase()) >= 0));
  }

  getLanguageDisplayText() {
    return;
  }
}
