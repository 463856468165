import { Component, Input } from '@angular/core';
import { MatMenuPanel as MatMenuPanel } from '@angular/material/menu';

@Component({
  selector: 'ak-user-picture',
  templateUrl: './user-picture.component.html',
  styleUrls: ['./user-picture.component.scss']
})
export class UserPictureComponent {
  @Input() src: string | null | undefined;
  @Input() menuTriggerFor: MatMenuPanel
  @Input() svgIcon: string;
  @Input() showNotificationBadge = false;

  @Input() profileImageAltText = 'your profile';
  @Input() profileButtonAriaLabel = 'your profile';
  @Input() notificationsAvailableAriaLabel = 'new notifications available';

  showFallback = false;

  imageLoadFailed() {
    this.showFallback = true;
  }
}
