import {Language} from './language';

export class LanguageGroup {
  languages: Array<Language>;

  constructor(languages: Array<Language>) {
    this.languages = languages;
  }
}

