import { Injectable } from '@angular/core';
import {Language} from '../language';
import {LANGUAGE_GROUPS} from '../language-list';

@Injectable({
  providedIn: 'root'
})
export class LanguagePickerService {

  constructor() { }

  getAllowedLanguages(): Language[] {
    return LANGUAGE_GROUPS
      .map(group => group.languages)
      .reduce((prev, curr) => {
        return prev.concat(curr);
      });
  }

  isValidLanguage(lang: string): boolean {
    return this.getAllowedLanguages().some((language) => language.code === lang);
  }
}
