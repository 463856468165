import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfiniteScrollerDirective } from './infinite-scroller.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [InfiniteScrollerDirective],
  exports: [InfiniteScrollerDirective]
})
export class InfiniteScrollerModule {}
