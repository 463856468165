import {inject, Injectable, InjectionToken} from '@angular/core';
import { MessageBoxSnackbarComponent } from '../snackbar/message-box-snackbar.component';
import {DialogPosition, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { MessageBoxDialogComponent } from '../dialog/message-box-dialog.component';
import type { MessageBoxOverlayConfig } from '../model/message-box-overlay-config';
import {MatSnackBar, MatSnackBarRef, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";

export const MESSAGE_BOX_OVERLAY_DEFAULT_POSITION = new InjectionToken<MatSnackBarVerticalPosition>('MESSAGE_BOX_OVERLAY_DEFAULT_POSITION');
export const MESSAGE_BOX_SNACKBAR_DURATION = new InjectionToken<number>('MESSAGE_BOX_SNACKBAR_DURATION');

@Injectable({
  providedIn: 'root',
})
export class MessageBoxService {
  constructor(private snackbar: MatSnackBar, private dialog: MatDialog) {}

  private DEFAULT_POSITION = inject(MESSAGE_BOX_OVERLAY_DEFAULT_POSITION, { optional: true, });
  private DEFAULT_SNACKBAR_DURATION = inject(MESSAGE_BOX_SNACKBAR_DURATION, { optional: true, }) || 2700;

  private openSnackbar: MatSnackBarRef<any>;
  private openDialog: MatDialogRef<any>;

  openAsSnackbar(config: MessageBoxOverlayConfig) {

    let verticalPosition: MatSnackBarVerticalPosition;

    if (config.position) {
      verticalPosition = config.position;
    } else if (this.DEFAULT_POSITION) {
      verticalPosition = this.DEFAULT_POSITION;
    } else {
      // legacy spec showed success snackbars at the bottom but all other
      // types at the top
      verticalPosition = config.type === 'success' ? 'bottom' : 'top';
    }

    const duration: number = config.showCloseButton ? 0 : (config.duration || this.DEFAULT_SNACKBAR_DURATION);

    this.openSnackbar = this.snackbar.openFromComponent(MessageBoxSnackbarComponent, {
      data: {
        type: config.type,
        onActionButtonClicked: () => {
          if (config.onActionButtonClicked) {
            config.onActionButtonClicked();
          }

          this.openSnackbar.dismiss();
        },
        onMessageBoxClosed: () => {
          this.snackbar.dismiss();
          if (config.onMessageBoxClosed) {
            config.onMessageBoxClosed();
          }
        },
        actionButtonText: config.actionButtonText,
        showCloseButton: config.showCloseButton,
        text: config.text,
        template: config.template,
        templateContext: config.templateContext || {}
      },
      panelClass: ['message-box-snackbar'],
      duration,
      horizontalPosition: 'center',
      verticalPosition,
    });
  }

  openAsDialog(config: MessageBoxOverlayConfig) {

    const position: DialogPosition = this.getDialogPosition(config.position);

    this.openDialog = this.dialog.open(MessageBoxDialogComponent, {
      data: {
        type: config.type,
        onActionButtonClicked: () => {
          if (config.onActionButtonClicked) {
            config.onActionButtonClicked();
          }

          this.openDialog.close();
        },
        actionButtonText: config.actionButtonText || 'action',
        showCloseButton: false,
        text: config.text,
        template: config.template,
        templateContext: config.templateContext || {},
      },
      panelClass: ['message-box-dialog'],
      disableClose: true,
      autoFocus: false,
      position,
    });
  }

  open(config: MessageBoxOverlayConfig) {
    this.closeOpenNotification();

    if (config.type === 'error') {
      this.openAsDialog(config);
    } else {
      this.openAsSnackbar(config);
    }
  }

  private closeOpenNotification() {

    if (this.openDialog) {
      this.openDialog.close();
      this.openDialog = null;
    }

    if (this.openSnackbar) {
      this.openSnackbar.dismiss();
      this.openSnackbar = null;
    }
  }

  close() {
    this.closeOpenNotification();
  }

  private getDialogPosition(position: 'top' | 'bottom' | undefined): DialogPosition {

    if (position) {
      return position === 'top' ? this.getPositionTop() : this.getPositionBottom();
    } else if (this.DEFAULT_POSITION) {
      return this.DEFAULT_POSITION === 'top' ? this.getPositionTop() : this.getPositionBottom();
    } else {
      // the legacy version of the dialog defaulted to top, so we keep that behaviour if no position is specified
      return this.getPositionTop();
    }
  }

  private getPositionTop(): DialogPosition {
    return {
      top: '24px',
      bottom: undefined
    }
  }

  private getPositionBottom(): DialogPosition {
    return {
      top: undefined,
      bottom: '24px'
    }
  }
}
