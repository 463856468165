<div class="input-file" *ngIf="viewData$ | async as view">
  <div class="dropzone-container">
    <ak-file-dropzone
      [title]="title"
      [acceptedTypes]="acceptStringList"
      [disabled]="isDisabled || view.maxFilesReached"
      (filesSelected)="this.filesAdded$.next($event)"
      (filesRejected)="this.filesRejected.next($event)"
      [clearSelectionAfterEmitted]="true"
    ></ak-file-dropzone>
  </div>
  <div class="file-list-container">
    <ng-container
      [ngTemplateOutlet]="selectedFilesTemplate || standardFileListing"
      [ngTemplateOutletContext]="{ files: view.selectedFiles, removeFileFunction: removeFile.bind(this) }"
    ></ng-container>
  </div>
</div>

<ng-template #standardFileListing let-files="files" let-removeFileFunction="removeFileFunction">
  <div class="standard-file-list-container" [class.disabled]="isDisabled">
    <div class="standard-file-list-row" *ngFor="let file of files; let i = index">
      <mat-icon class="file-icon" [svgIcon]="file | fileIcon | async"></mat-icon>
      <div class="file-name">{{ file.name }}</div>
      <button (click)="removeFileFunction(i)" aria-label="remove-file" [disabled]="isDisabled" mat-icon-button class="remove-button icon-button-small">
        <mat-icon svgIcon="ak-close"></mat-icon>
      </button>
    </div>
  </div>
</ng-template>
