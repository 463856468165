import { Pipe, PipeTransform } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

@Pipe({
  name: 'fileIcon',
})
export class FileIconPipe implements PipeTransform {
  constructor(private iconRegistry: MatIconRegistry) {}

  transform(file: File): Observable<string> {
    const fileNameElements = file.name.split('.');

    if (fileNameElements.length === 1) {
      return of('ak-file-default');
    }

    const fileExtension = fileNameElements[fileNameElements.length - 1];

    const iconName = `ak-file-${fileExtension}`;

    return this.iconRegistry.getNamedSvgIcon(iconName).pipe(
      // Because getNamedSvgIcon sometimes doesn't emit when the icon is not found in a set, we initialise with a default
      // This prevents a blank icon from being shown
      startWith('ak-file-default'),
      map((svg) => {
        return svg instanceof SVGElement ? iconName : 'ak-file-default';
      }),
      catchError((_err) => {
        return of('ak-file-default');
      })
    );
  }
}
