<div #widgetContainer class="widget-container" [hidden]="(visibleWidthPercentageSubject | async) >= 100">
  <div class="column-list">
    <div
      *ngFor="let columnWidth of columnPercentageWidthsSubject | async"
      [ngStyle]="{ width: columnWidth + '%' }"
      class="column"
    >
      <div class="inner"></div>
    </div>
  </div>

  <div
    [ngStyle]="{ width: (visibleWidthPercentageSubject | async) + '%' }"
    #dragMarker
    *ngIf="(visibleWidthPercentageSubject | async) < 100"
    class="scrollMarker"
    cdkDrag
    cdkDragLockAxis="x"
    [cdkDragBoundary]="widgetContainer"
    [cdkDragFreeDragPosition]="freeDragPosition$ | async"
    (cdkDragMoved)="updateScroll($event)"
    (cdkDragEnded)="dragEnd($event)"
  >
    <div class="hover-ripple" rippleHover></div>
  </div>
</div>
