import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFileComponent } from './input-file.component';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FileIconPipe } from './file-icon.pipe';
import { FileDropzoneModule } from '../file-dropzone/file-dropzone.module';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, FileDropzoneModule],
  declarations: [InputFileComponent, FileIconPipe],
  exports: [InputFileComponent, FileIconPipe]
})
export class InputFileModule {}
