import {Language} from './language';
import {LanguageGroup} from './language-group';

export const LANGUAGE_GROUP_LATIN = new LanguageGroup (
  [
    new Language('bs', 'bosanski'),
    new Language('da', 'dansk'),
    new Language('de', 'Deutsch'),
    new Language('et', 'eesti'),
    new Language('en', 'English'),
    new Language('en_UK', 'English, United Kingdom'),
    new Language('es', 'Español'),
    new Language('fr', 'français'),
    new Language('fr_CA', 'français, Canada'),

    new Language('hr', 'hrvatski'),
    new Language('is', 'íslenska'),
    new Language('it', 'Italiano'),
    new Language('lv', 'latviešu'),
    new Language('lt', 'lietuvių'),
    new Language('hu', 'magyar'),
    new Language('nl', 'Nederlands'),
    new Language('nb', 'norsk'),

    new Language('pl', 'polski'),
    new Language('pt', 'Português'),
    new Language('ro', 'Română'),
    new Language('sk', 'Slovenčina'),
    new Language('sl', 'Slovenščina'),
    new Language('sr-Latn', 'srpski'),
    new Language('fi', 'suomalainen'),
    new Language('sv', 'svenska'),
    new Language('vi', 'Tiếng Việt'),
    new Language('tr', 'Türkçe'),
  ]
);


export const LANGUAGE_GROUP_CYRILLIC = new LanguageGroup (
  [
    new Language('cs', 'čeština'),
    new Language('el', 'Ελληνικά'),
    new Language('bg', 'български'),
    new Language('ru', 'русский'),
    new Language('sr-Cyrl', 'српски'),
    new Language('uk', 'Українська')
  ]
);


export const LANGUAGE_GROUP_OTHER = new LanguageGroup (
  [
    new Language('ar', 'العربية'),
    new Language('fa', '‏فارسی‏'),
    new Language('hi', 'हिंदी'),
    new Language('th', 'ไทย'),
    new Language('zh-Hans', '简体中文'),
    new Language('ja', '日本語'),
    new Language('ko', '한국어'),
    new Language('zh-Hant', '繁體中文'),
    new Language('yue', '粵語 繁體中文')
  ]
);

export const LANGUAGE_GROUPS: Array<LanguageGroup> = [
  LANGUAGE_GROUP_LATIN,
  LANGUAGE_GROUP_CYRILLIC,
  LANGUAGE_GROUP_OTHER
];

