import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import type { NotificationType } from '../model/notification-type';

@Component({
  selector: 'ak-message-box-core',
  templateUrl: './message-box-core.component.html',
  styleUrls: ['./message-box-core.component.scss'],
})
export class MessageBoxCoreComponent implements OnInit, OnChanges {
  icon: string;
  showMessageBox = true;
  private icons: { [key in NotificationType]: string } = {
    error: 'ak-error',
    warning: 'ak-warning',
    success: 'ak-success',
    info: 'ak-info-filled',
  };

  @Input() type: NotificationType;
  @Input() actionButtonText?: string;
  @Input() showCloseButton = false;

  @Output() onMessageBoxClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() onActionButtonClicked: EventEmitter<boolean> = new EventEmitter();

  ngOnInit() {
    this.icon = this.icons[this.type];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type']) {
      this.icon = this.icons[this.type];
    }
  }

  close(): void {
    this.onMessageBoxClosed.emit(true);
    this.showMessageBox = false;
  }

  doAction(): void {
    this.onActionButtonClicked.emit(true);
  }
}
