import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import type { MessageBoxDataConfig } from '../model/message-box-data-config';

@Component({
  selector: 'ak-message-box-dialog',
  templateUrl: '../template/message-box-overlay.component.html',
  styleUrls: ['./message-box-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessageBoxDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MessageBoxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MessageBoxDataConfig,
    private sanitizer: DomSanitizer,
  ) {
  }

  get safeText(): SafeHtml {
    const parsedText = new DOMParser().parseFromString(this.data.text, 'text/html').documentElement.textContent;
    return this.sanitizer.bypassSecurityTrustHtml(parsedText);
  }
}
