import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageBoxComponent } from './message-box.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule as MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { MessageBoxDialogComponent } from './dialog/message-box-dialog.component';
import { MessageBoxSnackbarComponent } from './snackbar/message-box-snackbar.component';
import { MessageBoxCoreComponent } from './core/message-box-core.component';

@NgModule({
  declarations: [
    MessageBoxComponent,
    MessageBoxDialogComponent,
    MessageBoxSnackbarComponent,
    MessageBoxCoreComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
  ],
  exports: [MessageBoxComponent],
})
export class MessageBoxModule {
}
